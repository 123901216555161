.blog_regular_page {
  text-align: center;
}
.blog_regular_page.blog-1 {
  text-align: center;
  background-blend-mode: overlay;
  background-color: rgb(0, 0, 0, 0.5);
}
.blog_regular_page.blog-2 {
  text-align: center;
  background-blend-mode: overlay;
  background-color: rgb(0, 0, 0, 0.5);
}
.blog_regular_page.blog-3 {
  text-align: center;
  background-blend-mode: overlay;
  background-color: rgb(0, 0, 0, 0.5);
}
.blog_regular_page.blog-4 {
  text-align: center;
  background-blend-mode: overlay;
  background-color: rgb(0, 0, 0, 0.5);
}

.blog_regular_page .SectionOne {
  padding-top: 7%;
  padding-bottom: 7%;
}

.blog_regular_page .SectionOne h1 {
  font-family: "Garetra Demo" !important;
  font-style: normal;
  font-size: 60px;
}

.blog_regular_page .SectionOne div {
  color: #ffffff;
  font-size: 20px;
  padding: 3% 14%;
}

.blog_regular_page .SectionOne .image-container {
  position: relative;
  border: 1px solid white;
  height: fit-content;
}

.blog_regular_page .SectionOne .image-container img {
  top: -16%;
  left: -7%;
  position: absolute;
}

.blog_regular .SectionTwo .Side {
  padding-top: 6%;
  padding-bottom: 14%;
  background-image: url("../../../../../public/images/blog-recipe-side-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-left: 2%;
  padding-right: 2%;
  height: fit-content;
  text-align: center;
}

.blog_regular .SectionTwo .Side div {
  font-size: 15px;
}

.blog_regular .SectionTwo .Side .Heading {
  font-size: 40px;
  font-weight: 600;
  font-family: "Garetra Demo";
  line-height: 45px;
}

.blog_regular .SectionTwo .Side .TopCategories {
  font-size: 20px;
}

.blog_regular .SectionTwo .Side .DownloadButton {
  width: 80%;
  background-color: #657f76;
  border: none;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  cursor: pointer;
}

.blog_regular .SectionTwo .Side img,
.blog_regular .SectionTwo .Side div,
.blog_regular .SectionTwo .Side p {
  margin-bottom: 10%;
}

.blog_regular .SectionTwo .Main {
  background-color: #fdfdfd;
  text-align: left;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 3%;
}

.blog_regular .SectionTwo .Main img:first-of-type {
  width: 100%;
  height: auto;
}

.blog_regular .SectionTwo .Main .Para {
  font-size: 18px;
  font-family: "Courier New", Courier, monospace;
  padding-bottom: 10%;
}

.blog_regular .SectionTwo .Main .Para h3 {
  font-size: 30px;
  font-family: "Garetra Demo";
}

.blog_regular .SectionTwo .Main .Para p {
  margin-top: 2%;
  margin-bottom: 2%;
}

.blog_regular .SectionTwo .Main .Para h4 {
  font-size: 23px;
  font-style: italic;
}

.blog_regular .SectionTwo .Main .Para .SupportPeople li {
  margin-left: 5%;
}

.blog_regular .SectionTwo .Main .Para ul:last-child li {
  text-decoration: underline;
  margin-top: 15px;
}

.GreenButton {
  width: 100%;
  background-color: #657f76;
  border: none;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  cursor: pointer;
  margin-top: 10px;
}

.StylishHeading {
  font-size: 40px;
  font-weight: 600;
  font-family: "Garetra Demo";
}

.search-bar {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  background-color: #657f76;
}

.search-input {
  flex: 1;
  border: none;
  padding: 3px 5px;
  font-size: 16px;
  color: #ffffff;
}

.search-input::placeholder {
  color: #ffffff;
  font-weight: 300;
}

.search-input:focus-visible {
  outline: none;
}

.search-button {
  border: none;
  padding: 2px;
  cursor: pointer;
}

.search-button img {
  width: 75%;
  height: auto;
}

.blog_regular hr {
  margin-top: 4%;
  margin-bottom: 4%;
}

.blog_btn {
  text-transform: uppercase;
  background-color: #657f76;
  border: none;
  padding: 10px 35px;
  color: #fff;
  border-radius: 20px 0 20px 0;
  font-size: 13px;
  text-decoration: none;
}

.blog_btn a {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 767px) {
  .blog_regular_page .SectionOne .image-container img {
    width: 200px;
    top: -7%;
    left: -3%;
    position: absolute;
  }

  .blog_regular_page .SectionOne h1 {
    font-family: "Garetra Demo" !important;
    font-style: normal;
    font-size: 25px;
    margin-top: 100px;
  }

  .blog_regular_page .SectionOne div {
    color: #ffffff;
    font-size: 20px;
    padding: 3% 5%;
  }
}
