.main_header {
  background-color: #657f76;
  padding-left: 40px;
  padding-top: 50px;
  padding-bottom: 50px;
  height: 100%;
  position: relative;
  transition: 0.4s;
}

.main_header.expand_nav {
  padding-left: 50px;
}

.expand {
  width: 35px !important;
  cursor: pointer;
}

.expand-2 {
  width: 30px !important;
  cursor: pointer;
  position: absolute;
  left: 35px;
  top: 0;
  padding-top: 35px;
}

.scrollbar {
  position: absolute;
  right: -15px;
  bottom: 15px;
  cursor: pointer;
}

.main_header img {
  width: 35px;
  cursor: pointer;
}

.main_navbar {
  margin-top: 40px;
}

.main_navbar ul {
  list-style-type: none;
  padding-left: 0;
}

.main_navbar ul li {
  color: #fff;
  margin-bottom: 15px;
  letter-spacing: 1px;
  cursor: pointer;
  text-transform: uppercase;
}

.menu_dropdown span {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: fit-content;
}

.service_dropdown ul {
  padding-left: 20px;
}

.service_dropdown ul li {
  font-size: 11px;
  margin-bottom: 0;
  margin-top: 10px;
  letter-spacing: 0;
}

.show_mob {
  display: none;
}

.mob_nav {
  display: none;
}

@media (max-width: 767px) {
  .expand-2 {
    display: none;
  }

  .mob_nav {
    display: block;
  }

  .navbar-toggler-icon {
    background-image: url("/public/images/navbar_menu.gif");
  }

  .show_mob {
    display: block;
    padding: 0;
  }

  .show_web {
    display: none;
  }

  .navbar > .container {
    justify-content: start !important;
  }

  .navbar-toggler {
    border: none;
  }

  .navbar {
    background-color: #657f76 !important;
  }

  .navbar-collapse {
    padding: 0 30px;
  }

  .navbar-collapse .nav-link,
  .navbar-collapse .nav-link.active {
    color: #fff;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }
}
