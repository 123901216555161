.home_banner {
  overflow: hidden;
}

.home_banner .col-md-3,
.home_banner .col-md-4,
.home_banner .col-md-5 {
  padding: 0;
}

.home_banner_center {
  padding: 0;
  height: 100vh;
  background-position: bottom;
}

.home_banner_inner {
  background-color: #f6dbe2a6;
  height: 100%;
  padding: 50px 20px 20px 20px;
}

.reetu_banner_img {
  height: 100vh;
  object-fit: cover;
  object-position: right;
}

.home_banner_center img {
  width: 300px;
}

.home_banner_center h2 {
  font-family: "Garetra Demo";
  font-size: 38px;
  margin-top: 30px;
}

.home_banner_center p {
  color: #231f20;
  margin-top: 20px;
}

.home_banner_center button {
  text-transform: uppercase;
  background-color: #657f76;
  border: 1px solid #657f76;
  padding: 6px 22px;
  color: #fff;
  border-radius: 10px 0 10px 0;
  margin-top: 10px;
  transition: all 0.2s ease;
}

.home_banner_center button:hover {
  background-color: #657f76b5;
  border: 1px solid #657f76b5;
}

.retu_banner img {
  width: 100%;
  height: 650px;
  object-fit: cover;
  object-position: center;
}

.quote_banner {
  background-image: url("/public/images/quote_bg_section.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 100px 0;
  margin-top: -1px;
}

.quote_banner h1 {
  color: #657f76;
  font-size: 37px;
  font-weight: 300;
}

.quote_text {
  margin-top: 45px;
}

.about_main {
  background-image: url("/public/images/about_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 70px;
  margin-top: -1px;
}

.about_text {
  margin-top: 50px;
}

.about_text h3 {
  font-family: "Garetra Demo";
  text-transform: uppercase;
  font-size: 35px;
  color: #000;
}

.about_text h4 {
  font-family: "Garetra Demo";
  text-transform: capitalize;
  font-size: 32px;
  margin-top: 20px;
  color: #000;
}

.about_text p {
  color: #231f20;
}

.about_text button {
  text-transform: uppercase;
  background-color: #657f76;
  border: 1px solid #657f76;
  padding: 6px 22px;
  color: #fff;
  border-radius: 10px 0 10px 0;
  margin-top: 10px;
  transition: all 0.2s ease;
}

.about_text button:hover {
  background-color: #fff;
  border: 1px solid #657f76;
  color: #657f76;
}

.services_main {
  background-image: url("/public/images/service_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 90px;
  padding-bottom: 50px;
}

.services_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.services_heading h1 {
  font-family: "Garetra Demo";
  text-transform: capitalize;
  font-size: 40px;
  color: #000;
}

.service_box img {
  border: 6px solid #fff;
  border-radius: 35px;
}

.service_box h5 {
  color: #657f76;
  text-align: center;
  margin: 15px 0;
  font-size: 22px;
}

.service_box button {
  text-transform: uppercase;
  background-color: #657f76;
  border: 1px solid #657f76;
  padding: 6px 22px;
  color: #fff;
  border-radius: 10px 0 10px 0;
  transition: all 0.2s ease;
}

.service_box button:hover {
  background-color: transparent;
  border: 1px solid #657f76;
  color: #657f76;
}

.testimonial_main {
  background-color: #e7f2ea;
  padding: 100px 0 50px;
  position: relative;
}

.testimonial_comas {
  display: flex;
  align-items: end;
  gap: 50px;
  justify-content: space-between;
  position: relative;
}

.testimonial_comas::before {
  content: "";
  position: absolute;
  top: 35px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #0000003d;
}

/* .testimonial_comas div {
  width: 100%;
  border-bottom: 1px solid #9a9d9e;
} */

.testimonial_comas img {
  width: 130px;
  background-color: #e7f2ea;
  position: relative;
  padding-right: 10px;
}

.testimonial_img img {
  position: absolute;
  /* bottom: 5px; */
  height: 300px;
  width: 540px;
  object-fit: cover;
  left: 0;
  top: 0;
}

.testimonial_head {
  /* position: relative; */
  /* bottom: 42px; */
  width: 500px;
}

.testimonial_head h2 {
  color: #231f20;
  font-family: "Garetra Demo";
  text-align: center;
  border-bottom: 1px solid #0000003d;
  padding-bottom: 5px;
  margin-bottom: 0;
}

.testimonial_head p {
  padding-left: 35px;
  color: #808184;
  margin-bottom: 0;
  margin-top: 15px;
}

.testimonial_show {
  background-color: #657f76;
  border-radius: 10px 10px 40px 10px;
  padding: 40px 25px 60px;
  position: relative;
  z-index: 1;
}

.client_testimonials {
  position: relative;
  padding: 30px 0 85px 0;
}

.testimonial_show p,
.testimonial_show span {
  color: #fff !important;
  line-height: 30px;
  font-size: 15px;
  font-weight: 300;
}

.quote_div {
  margin-top: 15px;
}

.quote_div img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  background-color: #657f76;
  padding: 8px;
  border-radius: 10px;
  margin-right: 7px;
}

.quote_div img:hover {
  cursor: pointer;
  opacity: 0.5;
}

.client_testimonials_main {
  overflow: hidden !important;
}

.testimonial_show img {
  width: 100px;
}

.testimonial_custom_slick_next {
  background-image: url("/public/images/arrow_next.png") !important;
  width: 30px !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  left: 4% !important;
  height: 30px !important;
  z-index: 9999999999999999 !important;
  top: 90% !important;
}

.testimonial_custom_slick_next:hover {
  opacity: 0.5;
}

.testimonial_custom_slick_prev {
  background-image: url("/public/images/arrow_left.png") !important;
  width: 30px !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  left: 0% !important;
  height: 30px !important;
  z-index: 9999999999999999 !important;
  top: 90% !important;
}

.testimonial_custom_slick_prev:hover {
  opacity: 0.5;
}

.client_testimonials_main .slick-prev:before,
.client_testimonials_main .slick-next:before {
  color: transparent !important;
}

.blog_main {
  background-image: url("/public/images/blog_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 90px 0;
}

.blog_main h1 {
  color: #231f20;
  font-family: "Garetra Demo";
  text-align: center;
  margin-bottom: 30px;
}

.blog_box {
  cursor: pointer;
  transition: 0.1s;
}

.blog_box div {
  text-align: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 20px 15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.blog_main .blog_box_main .blog_box img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.blog_main .blog_box_main:nth-child(odd) .blog_box img {
  height: 275px;
  width: 100%;
}
.blog_main .blog_box_main:nth-child(even) .blog_box img {
  height: 360px;
  width: 100%;
}

.blog_box div span {
  color: #b8b4b4;
  font-size: 12px;
}

.blog_box div a {
  text-transform: uppercase;
  background-color: #657f76;
  border: none;
  padding: 5px 20px;
  color: #fff;
  border-radius: 10px 0 10px 0;
  font-size: 13px;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .home_banner_center h2 {
    font-size: 35px;
  }

  .quote_banner h1 {
    font-size: 42px;
  }

  .about_text {
    margin-top: 20px;
  }

  .about_text h3 {
    font-size: 32px;
  }

  .about_text img {
    width: 375px;
  }

  .about_text h4 {
    font-size: 30px;
  }

  .about_text p {
    font-size: 14px;
  }

  .about_text button {
    margin-top: 0;
  }

  .testimonial_head p {
    margin-top: 10px;
  }

  .client_testimonials_main {
    bottom: 105px;
  }

  .testimonial_show p {
    font-size: 14px;
  }

  .testimonial_head h2 {
    font-size: 26px;
  }

  .testimonial_comas img {
    width: 130px;
  }

  .newsletter_div h4 {
    font-size: 20px;
  }

  .service_box h5 {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .home_banner_center h2 {
    font-size: 32px;
  }

  .newsletter_div h4 {
    font-size: 18px;
  }

  .footer_left h2 {
    font-size: 26px;
  }

  .social_media {
    gap: 15px;
  }

  .social_media h6 {
    font-size: 12px;
  }

  .footer_left h5 {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .home_banner_center img {
    width: 260px;
  }

  .home_banner_center h2 {
    font-size: 28px;
  }

  .container {
    max-width: 880px !important;
  }

  .quote_banner h1 {
    font-size: 38px;
  }

  .about_text img {
    width: 340px;
  }

  .about_text h4 {
    font-size: 28px;
  }

  .about_text p {
    font-size: 13px;
  }

  .about_text button,
  .service_box button {
    padding: 4px 20px;
    font-size: 13px;
  }

  .service_box h5 {
    font-size: 18px;
  }

  .social_media div {
    gap: 8px !important;
  }
}

@media (max-width: 767px) {
  .retu_banner img {
    height: auto;
  }

  .home_banner_center {
    padding: 0;
    background-size: 100% 100%;
    height: 500px;
  }

  .quote_banner {
    background-size: cover;
    padding: 100px 15px;
    background-image: none;
    background-color: #e7f2ea;
  }

  .blog_main {
    background-size: cover;
  }

  .quote_text {
    margin-top: 20px;
  }

  .quote_banner h1 {
    font-size: 27px;
  }

  .about_main {
    background-size: cover;
  }

  .services_heading {
    flex-direction: column-reverse;
    gap: 40px;
  }

  .service_box {
    margin-bottom: 30px;
  }

  .services_main {
    background-image: none;
  }

  .client_testimonials_main {
    bottom: 0px;
    padding: 10px;
  }

  .testimonial_head {
    position: relative;
    margin: 20px 0;
    bottom: 0;
  }

  .testimonial_custom_slick_next {
    display: none !important;
  }

  .testimonial_custom_slick_prev {
    display: none !important;
  }

  .blog_box {
    margin-bottom: 20px;
  }

  .get_started img {
    height: 150px;
    object-fit: cover;
    border-radius: 20px;
  }

  .get_started button {
    padding: 10px 40px 8px 40px;
    font-size: 17px;
  }

  .footer_left ul {
    font-size: 16px;
    flex-flow: wrap;
    justify-content: center;
    gap: 0 20px;
  }

  .footer_left {
    background-color: #657f76;
    width: 100%;
    padding: 30px 35px 20px 25px;
    border-top-right-radius: 50px;
  }

  .social_media {
    margin-top: 70px;
  }

  .footer_signature {
    margin-top: 10px;
    padding: 40px;
  }

  .footer_main {
    background-size: cover;
  }

  .quote_text img {
    height: 20px;
    object-fit: cover;
    object-position: left;
  }

  .quote_div img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    background-color: #657f76;
    padding: 8px;
    border-radius: 10px;
    margin-right: 7px;
  }
}
@media (max-width: 490px) {
  .testimonial_comas::before {
    background-color: transparent;
  }
  .testimonial_head h2 {
    transform: translateY(20px);
    font-size: 18px;
    border: none;
  }
  .blog_main .blog_box_main:nth-child(odd) .blog_box img {
    height: 370px;
    width: 100%;
  }
  .blog_main .blog_box_main:nth-child(even) .blog_box img {
    height: 420px;
    width: 100%;
  }
  .testimonial_comas img {
    width: 90px;
  }
  .testimonial_main {
    padding: 50px 0 50px;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 491px) and (max-width: 767px) {
  .blog_main .blog_box_main:nth-child(odd) .blog_box img {
    height: 430px;
    width: 100%;
  }

  .blog_main .blog_box_main:nth-child(even) .blog_box img {
    height: 490px;
    width: 100%;
  }
}
