.faqs_main {
  background-color: #657f76;
  padding: 50px 0;
}

.faqs_main h1,
.faqs_main h2 {
  font-family: "Garetra Demo";
  color: #fff;
  text-align: center;
}

.faqs_main .accordion-button {
    padding: 0;
    padding-left: 20px;
    text-transform: uppercase;
    font-weight: 400;
    word-spacing: 2px;
    letter-spacing: 1px;
    font-size: 15px;
}

.faqs_main .accordion-button::after {
  background-image: url("/public/images/plus_icon.png") !important;
  background-size: cover !important;
  width: 65px;
  height: 60px;
  transition: none !important;
}

.accordion-header {
    margin-bottom: 0 !important;
    background-color: #fff !important; 
}

.faqs_main .accordion-item {
    border-radius: 0;
    margin-bottom: 20px;
    border: none;
    background-color: transparent;
}

.faqs_main .accordion-button:not(.collapsed){
    background-color: transparent;
    border-bottom: 1px solid #0000001a;
}

.faqs_main .accordion-header button{
    color: #606060;
    font-family: "Roboto", sans-serif;
}

.accordion-collapse{
    background-color: rgb(255, 255, 255);
    max-width: 100% !important;
    border-radius: 0 !important;
}

.accordion-button:focus{
    box-shadow: none;
}

.faqs_main .accordion-body{
    max-width: 1000px;
    font-size: 15px;
    color: #6d6e71;
}

/* .faqs_main .accordion-button:not(.collapsed)::after {
  background-image: url("/public/images/minus_icon.png") !important;
  background-size: cover !important;
} */
