@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

@font-face {
  font-family: "Garetra Demo";
  src: url("/public/fonts/GaretraDemo.woff2") format("woff2"),
    url("/public/fonts/GaretraDemo.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Amalina";
  src: url("/public/fonts/Amalina.woff2") format("woff2"),
    url("/public/fonts/Amalina.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

.container-fluid {
  overflow-x: hidden;
}

.testimonial_header {
  background-color: #efa2a2;
}

.testimonial_top_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.testimonial_top_header img {
  width: 200px;
}

.testimonial_top_header button {
  border: none;
  color: #606060;
  padding: 5px 20px 4px 20px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 17px;
  font-size: 14px;
  transition: all 0.2s ease;
  border: solid 2px #ffffff;
}

.testimonial_top_header button:hover {
  border: solid 2px #ffffff;
  color: #ffffff;
  background-color: transparent;
}

.active ~ .page_responsive {
  width: calc(100% - 220px);
  left: 220px;
  transition: all 0.2s ease;
  overflow-x: hidden;
  border-top: 1px solid #00000014;
}

.page_responsive {
  position: absolute;
  padding-left: 90px;
  padding-right: 20px;
  padding-top: 10%;
  height: 100vh;
  background-image: url("./assets/blog_section1_bg.png");

  left: 75px;
  transition: all 0.2s ease;
  overflow-x: hidden;
  border-top: 1px solid #00000014;
}

.page_responsive::-webkit-scrollbar {
  margin-top: 3px;
  width: 5px;
  cursor: pointer;
}

.page_responsive::-webkit-scrollbar-track {
  background: #e4e4e5;
}

.page_responsive::-webkit-scrollbar-thumb {
  background: #1a2d4b;
  cursor: pointer;
}

@media (max-width: 767px) {
  .testimonial_top_header img {
    width: 150px;
  }
}
