.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 999;
  background-color: #ffffff;
  width: 20%;
  transition: 0.5s;
}

.sidebar.hide {
  width: 0% !important;
}

.sidebar.hide .main_header {
  padding-left: 0px !important;
}

.about_main_banner {
  background-image: url("/public/images/ritu-32.jpg");
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: overlay;
}

.about_main_banner h2 {
  color: #fff;
  font-family: "Garetra Demo";
  font-size: 55px;
  text-align: center;
  margin-top: 40px;
}

.about_main.about_page {
  padding-bottom: 70px;
  background-image: none;
  background-color: #fff;
}
.about_main.about_page .about_left_img img {
  height: 500px;
  object-fit: cover;
}

.about_main.about_page .about_text {
  margin-top: 0;
  background-color: #fff;
  padding: 30px;
  position: relative;
  left: -90px;
}

.about_main.about_page .about_text p {
  margin-bottom: 0;
  font-size: 15px;
}

.health_section {
  /* background-image: url("/public/images/health_banner_bg.png"); */
  background-size: cover;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-attachment: fixed;
  background-position: center;
}

.health_section_text {
  max-width: 750px;
  margin: auto;
  color: #fff;
  margin-top: 40px;
  text-align: center;
}

.health_section_text h2 {
  font-family: "Garetra Demo";
}

.health_section_text h2 span {
  font-family: "Garetra Demo";
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 1px;
}

.health_section_text p {
  font-weight: 300;
  margin-bottom: 0;
}

.health_section_text button {
  color: #606060;
  background-color: #fff;
  padding: 10px 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 2px solid #fff;
  margin-top: 50px;
  transition: all 0.2s ease;
}

.health_section_text button:hover {
  color: #fff;
  border: 2px solid #fff;
  background-color: transparent;
}

.certified_main {
  background-image: url("/public/images/certified_bg.png");
  background-size: 100% 100%;
  padding: 60px 0;
  margin-top: -2px;
}

.certified_main h1 {
  font-family: "Garetra Demo";
  text-align: center;
  margin-bottom: 50px;
  color: #231f20;
}

.certified_main .slick-prev:before,
.certified_main .slick-next:before {
  color: #606060 !important;
  font-size: 26px;
}

.certified_main .slick-prev {
  top: 40%;
}

.certified_main .slick-next {
  top: 40%;
}

.around_main {
  padding: 60px 0;
  background-image: url("/public/images/about_ready_bg.png");
  background-size: 100% 100%;
  margin-top: -2px;
}

.around_main h3 {
  text-align: center;
  color: #606060;
  font-weight: 400;
  font-size: 25px;
}

.around_main h2 {
  font-family: "Garetra Demo";
  text-align: center;
  font-size: 36px;
  margin-bottom: 40px;
}

.around_box {
  text-align: center;
}

.around_box h5 {
  font-family: "Garetra Demo";
  margin-top: 13px;
  font-size: 24px;
}

.around_box p {
  color: #6d6e71;
  font-size: 15px;
  font-weight: 300;
}

.around_box button {
  text-transform: uppercase;
  border: none;
  background-color: #657f76;
  color: #fff;
  padding: 6px 17px;
  letter-spacing: 1px;
  font-size: 14px;
  border: 1px solid #657f76;
  transition: all 0.2s ease;
}

.around_box button:hover {
  background-color: #fff;
  color: #657f76;
  border: 1px solid #657f76;
}

.instagram_main {
  background-color: #fceded;
  padding: 50px 0;
}

.instagram_main h2 {
  text-align: center;
  font-family: "Garetra Demo";
  margin-bottom: 20px;
  font-size: 35px;
}

.insta_box {
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
}

.insta_box img {
  transition: all 0.6s ease-in !important;
}

.insta_box:hover:before {
  content: "";
  background-color: #79888085;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: 0.3s;
}

.insta_box .instagram_icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  opacity: 0;
}

.insta_box:hover .instagram_icon {
  opacity: 1;
}

.footer_details.about_page .footer_left {
  border-radius: 0px;
}

.footer_details.about_page .footer_left ul {
  color: #fff;
  display: flex;
  list-style-type: none;
  justify-content: start;
  font-size: 14px;
  padding-left: 0;
  margin-bottom: 40px;
  gap: 40px;
}

.footer_details.about_page .newsletter_div {
  margin-top: 0;
}

.footer_details.about_page .newsletter_div h4 {
  color: #fff;
  margin-bottom: 20px;
  font-family: "Garetra Demo";
}

.footer_details.about_page .email_div input {
  border-radius: 4px;
  border: none;
  width: 200px;
  padding: 2px 0;
}

.footer_details.about_page .newsletter_div .sub_btn {
  border: none;
  color: #657f76;
  font-size: 13px;
  padding: 3px 14px;
  margin-top: 10px;
  margin-left: 55px;
  border-radius: 5px;
}

.certified_main .slick-next {
  right: 5px;
}

.certified_main .slick-prev {
  left: -40px;
}

@media (max-width: 1200px) {
  .footer_details.about_page .footer_left ul {
    gap: 25px;
  }
}

@media (max-width: 991px) {
  .footer_details.about_page .footer_left ul {
    flex-flow: wrap;
    gap: 12px;
  }

  .about_main.about_page .about_text p {
    margin-bottom: 0;
    font-size: 12px;
  }

  .about_main.about_page .about_text {
    margin-top: 0;
    background-color: #fff;
    padding: 20px;
    position: relative;
    left: -60px;
  }

  .about_main.about_page .about_text h3 {
    font-size: 25px;
  }

  .about_main.about_page .about_text h4 {
    font-size: 21px;
  }
}

@media (max-width: 767px) {
  .around_main {
    background-size: initial;
  }

  .about_main_banner {
    height: 30vh;
  }

  .about_main_banner h2 {
    margin-top: 0;
    font-size: 30px;
  }

  .about_main.about_page .about_text {
    left: 0;
    padding: 30px 5px;
  }

  .about_main.about_page {
    padding-bottom: 30px;
  }

  .health_section {
    background-size: cover;
    height: auto;
    background-position: center;
    padding: 35px 10px;
  }

  .health_section .health_section_text {
    margin-top: 0;
  }

  .certified_main .slick-slide img {
    margin: auto;
  }

  .certified_main .slick-next {
    right: 5px;
  }

  .certified_main .slick-prev {
    left: 0px;
  }

  .certified_main {
    padding: 30px 0 60px;
    background-size: cover;
  }

  .certified_main h1 {
    margin-bottom: 20px;
  }

  .around_box {
    text-align: center;
    margin-bottom: 25px;
  }

  .around_box p {
    font-size: 16px;
  }

  .insta_box {
    margin-bottom: 15px;
  }

  .footer_details.about_page .footer_left ul {
    gap: 10px;
  }

  .footer_details.about_page .email_div input {
    width: 100%;
  }

  .footer_details.about_page .newsletter_div h4 {
    margin-top: 20px;
  }
}
