.dashboard-item-container {
  background-color: #657f76;
  border-radius: 14px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 40%;
  padding: 15px 13px;
  align-items: center;
  color: whitesmoke;
  margin-top: 15px;
}
.dashboard-item-container > h4 {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.dashboard-item-container > svg {
  width: 50px;
  height: 50px;
}
.dashboard-link-container {
  width: 40%;
  margin: auto;
  margin-top: 15px;
  padding: 13px 5px;
  border-radius: 13px;
  border: 1px solid #657f76;
}
.dashboard-link-container > h4 {
  padding: 0 8px;
}
.dashboard-link-container > div {
  display: flex;
  gap: 18px;
  padding: 0 8px;
}
.dashboard-link-container > div > a {
  color: #657f76;
  font-weight: bold;
  text-decoration: none;
}
