.footer_main {
  background-image: url("/public/images/footer_bg.png");
  background-size: 100% 100%;
  padding-top: 55px;
  margin-top: -1px;
}

.get_started {
  position: relative;
}
.get_started img {
  object-fit: cover;
  object-position: top;
  height: 320px;
  width: 100%;
  border-radius: 40px;
}

.get_started button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0a3a3;
  border: none;
  padding: 4px 40px 2px 40px;
  color: #fff;
  border-radius: 10px 0 10px 0;
  font-size: 26px;
  font-family: "Garetra Demo";
}

.get_started button:hover {
  background-color: transparent;
  border: #f0a3a3 solid 2px;
}

.get_started {
  margin-bottom: 35px;
}

.footer_details {
  overflow: hidden;
}

.footer_left {
  background-color: #657f76;
  width: 100%;
  padding: 40px 35px 20px 60px;
  border-top-right-radius: 50px;
}

/* .newsletter_div {
    margin-top: 30px;
} */

.newsletter_div h4 {
  color: #fff;
  margin-bottom: 20px;
}

.email_div {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #fff;
}

.email_div input {
  padding-left: 10px !important;
  border-radius: 4px;
  border: none;
  width: 200px;
  padding: 2px 0;
}

.email_div input:focus {
  border-color: #ffffff;
  box-shadow: 0 0 5px #ffffff;
  outline: none;
}

.email_div label {
  font-size: 14px;
}

.footer_left ul {
  color: #fff;
  display: flex;
  list-style-type: none;
  /* justify-content: space-between; */
  font-size: 14px;
  padding-left: 0;
  margin-bottom: 20px;
  gap: 45px;
}

.footer_left ul li {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.footer_left ul li:hover {
  background-color: #ffffffcc;
  color: #657f76;
  cursor: pointer;
}

.footer_left h2 {
  font-family: "Garetra Demo";
  color: #fff;
  word-spacing: -5px;
}

.footer_left p {
  max-width: 400px;
  color: #fff;
  font-weight: 100;
  font-size: 15px;
}

.social_media {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 90px;
}

.social_media > div > img:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.social_media h6 {
  margin-bottom: 0;
  color: #fff;
}

.footer_left h5 {
  font-size: 17px;
  padding-top: 20px;
  color: #fff;
}

.footer_signature {
  margin-top: 100px;
}

.sub_btn {
  border: none;
  color: #657f76;
  font-size: 13px;
  padding: 3px 14px;
  margin-top: 10px;
  margin-left: 55px;
  border-radius: 5px;
}
