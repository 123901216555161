.site_modal .modal-content {
  border-radius: 15px;
}
.modal-body {
  padding: 15px 25px !important;
  border-radius: 20px !important;
}
.modal-body hr {
  width: 95%;
  margin: 0 auto;
}
.review_header {
  display: contents;
}
.modal-body p {
  color: #000;
  font-weight: 500;
}
.review_header h4 {
  color: #657f76;
  margin-bottom: 0;
}
.review_header svg {
  font-size: 24px;
  color: #657f76;
  cursor: pointer;
}
.modal-body > div > div {
  display: flex;
  justify-content: space-evenly;
}
.modal-body button {
  border: none;
  outline: none;
  padding: 5px 13px;
  border-radius: 5px;
  background-color: #657f76;
  color: whitesmoke;
}
