.tabs .nav-link {
  padding: 13px 35px !important;
  color: black !important;
  border-radius: 0;
  font-weight: 500;
}

.tabs .nav-link.active {
  text-decoration: none !important;
  border-radius: 0;
  color: #fff !important;
  font-weight: 700;
  background-color: #e57424;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.add_more_fields button {
  border: 1px solid #fff;
  color: #fff;
}

.tabs.nav.nav-tabs {
  display: flex;
  justify-content: center;
}

.tabs_btn button {
  border-width: 1px;
  border-color: #657f76;
  border-style: solid;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 15%);
  width: 160px;
  aspect-ratio: 4/1;
  color: #657f76;
  font-weight: 600;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 11px;
}

.tabs_btn button.active {
  background-color: #657f76;
  color: #fff;
  border-color: rgb(235, 235, 235);
}

.tabs_btn button:hover {
  background-color: #657f76;
  color: #fff;
  border-color: rgb(235, 235, 235);
}

.tabs_description .form-label {
  font-weight: 600;
  margin-bottom: 0;
  font-size: 14px;
  color: #002746;
}

.add_more_fields button {
  border-width: 1px;
  border-color: rgb(229, 116, 36);
  border-style: solid;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 15%);
  padding: 8px 35px;
  color: rgb(229, 116, 36);
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 14px;
}

.cms_submit_btn {
  border-width: 1px;
  border-color: rgb(235, 235, 235);
  border-style: solid;
  border-radius: 12px;
  background-color: #657f76;
  box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 15%);
  padding: 8px 20px;
  color: #fff;
  font-weight: 600;
  margin-left: 20px;
}

.cms_submit_btn:hover {
  background-color: #fff;
  color: #657f76;
  border: 1px solid #657f76;
}

.previewImg_div {
  position: relative;
}

.previewImg_div svg {
  position: absolute;
  color: #fff;
  font-size: 23px;
  cursor: pointer;
}

@media screen and (max-width: 1500px) {
  .tabs_btn button {
    width: 140px;
  }
}
@media screen and (max-width: 1300px) {
  .home-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-section-container > div:first-child > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-section-container > div:first-child > div:first-child > button {
    padding: 13px;
  }
}
.section_delete_btn {
  position: absolute;
  background-color: rgb(204, 20, 20);
  border: none;
  outline: none;
  width: 25px;
  aspect-ratio: 1/1;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 50%;
  color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section_delete_btn:hover {
  background-color: rgb(204, 20, 20, 0.8);
}
