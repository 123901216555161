.auth_main {
  background-image: url("/public/images/auth_bg.png");
  /* height: 100vh; */
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 70px 0;
}

.auth_box {
  background-image: url("/public/images/signup_bg.png");
  background-size: 100% 100%;
  max-width: 600px;
  padding: 35px;
  border-radius: 20px;
  margin: auto;
}

.auth_box h2 {
  text-align: center;
  font-family: "Amalina";
  font-weight: 900;
  font-size: 27px;
}

.auth_box h3 {
  font-family: "Garetra Demo";
  text-align: center;
  color: #606060;
  font-size: 32px;
  margin: 15px 0;
}

.auth_box input {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.auth_box input::placeholder {
  color: #939598;
  font-size: 14px;
}

.auth_box textarea::placeholder {
  color: #939598;
  font-size: 14px;
}

.form-control:focus {
  border-color: #657f76;
  box-shadow: none;
}

.auth_box .submit_btn {
  background-color: #657f76;
  color: #fff;
  width: 100%;
  border: none;
  padding: 6px 0;
  border-radius: 0px 10px 0px 10px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 20px;
  border: #657f76 2px solid;
  transition: all 0.2s ease;
}

.auth_box .submit_btn:hover {
  background-color: transparent;
  color: #657f76;
  border: #657f76 2px solid;
}

.auth_box span {
  display: flex;
  justify-content: center;
  font-size: 15px;
  color: #58595b;
}

.auth_box .straight_line {
  width: 30px;
  height: 1px;
  background-color: #acacad;
  margin: auto;
  transform: rotate(90deg);
  margin-top: 20px;
  overflow: hidden;
  margin-bottom: 20px;
}

.auth_box .auth_social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.auth_box .auth_social img {
  width: 40px;
}

.auth_box p {
  text-align: center;
  margin-top: 10px;
  font-size: 15px;
}

.auth_box p strong {
  color: #657f76;
  cursor: pointer;
}

.auth_box h6 {
  font-size: 13px;
  text-align: end;
  padding: 10px 0;
  color: #939598;
  font-weight: 400;
  cursor: pointer;
}

.work_location {
  height: 100%;
  /* background-color: #fff; */
  padding: 20px;
  border-radius: 20px;
}

.work_location h3 {
  font-family: "Garetra Demo";
  color: #606060;
  font-size: 32px;
  margin: 15px 0;
}
.auth_main.contact_page {
  height: auto;
}
@media (max-width: 767px) {
  .work_location {
    margin-bottom: 100px;
  }
}
