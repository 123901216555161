.navbar {
  padding: 20px 0;
  width: 300px;
  float: right;
  z-index: 1;
  color: #fff;
}
.navbar-brand img {
  width: 175px;
}
.navbar-nav {
  align-items: center;
}
.navbar-nav a {
  color: #fff;
  margin-right: 55px;
  font-weight: 400;
  text-decoration: none;
  font-size: 15px;
}
.navbar-nav a:hover {
  color: #d0d2d3;
}
.navbar-nav .get_a_quote {
  border: 1px solid #e57424;
  border-radius: 8px;
  background-color: rgb(229, 116, 36);
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 15%);
  padding: 5px 25px;
  color: #fff;
  font-weight: 500;
  margin-left: 20px;
  font-size: 13px;
}
.navbar-nav .get_a_quote:hover {
  border: 1px solid #e57424;
  color: #e57424;
  background-color: #fff;
}
.email_us {
  text-decoration: none !important;
  color: #fff !important;
}
#basic-navbar-nav {
  padding: 1rem 3rem 1rem 1rem;
  border-radius: 1rem;
  background-color: #657f76;

  align-items: center;
  justify-content: start;
}
@media screen and (max-width: 767px) {
  .navbar {
    width: 100%;
  }
  .navbar-nav {
    align-items: flex-start;
  }
  .navbar > .container {
    justify-content: start !important;
  }
}
