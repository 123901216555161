.blog-section {
  background-color: #657f76;
  padding: 13px;
  margin-bottom: 13px;
  border-radius: 13px;
  color: whitesmoke;
}
.blogData-switch {
  margin-left: 15px;
}
.blogData-switch > input {
  transform: scale(150%) !important;
  background-color: #657f76 !important;
  border: 1px solid #657f76 !important;
}
