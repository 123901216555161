.about_main_banner.testimonial_page.blog {
  background-image: url("/public/images/testimonial_banner.png");
  height: 89vh;
}

.blog_main.blog_page {
  background-image: none;
  padding: 80px 0;
}

.blogs_page h3 {
  color: #fff;
  font-family: "Garetra Demo";
  font-size: 32px;
  text-align: center;
}

.blog_main.blog_page h6 {
  font-style: italic;
  font-weight: 300;
}

.blog_main.blog_page p {
  font-family: "Garetra Demo";
  font-size: 20px;
  color: #000;
}

.about_main.about_page.blog_page {
  background-image: url("/public/images/blog_section1_bg.png");
}

.about_main.about_page.blog_page button {
  background-color: #efa2a2;
  border: 1px solid #efa2a2;
  border-radius: 0px;
}

.about_main.about_page.blog_page button:hover {
  background-color: #fff;
  color: #efa2a2;
}

.food_blog {
  background-color: #f0d5dd;
  background-image: url("/public/images/food_freedom_bg.png");
  background-size: cover;
  padding: 60px 0;
}

.food_blog .container {
  text-align: center;
}

.food_blog h5 {
  font-family: "Garetra Demo";
}

.food_blog h1 {
  font-family: "Garetra Demo";
}

.food_blog .container .give_line {
  max-width: 250px;
  height: 1px;
  background-color: #736f70;
  margin: 30px auto;
}

.food_blog .container p {
  color: #606060;
  max-width: 750px;
  margin: auto;
}

.trending_category {
  background-color: #f6f7f7;
  padding: 70px 0;
}

.trending_main h1 {
  color: #231f20;
  font-family: "Garetra Demo";
  text-align: center;
  margin-bottom: 45px;
  text-transform: uppercase;
}

.now_trending {
  border-bottom: 2px solid #7373738f;
  padding-bottom: 75px;
}

.trending_box {
  display: flex;
  gap: 20px;
  cursor: pointer;
}

.trending_box .trending_text {
  padding-top: 15px;
}

.trending_box .trending_text h6 {
  font-family: "Garetra Demo";
  font-size: 20px;
  font-style: italic;
}

.trending_box .trending_text h5 {
  font-family: "Garetra Demo";
  font-size: 24px;
}

.category_main {
  padding-top: 90px;
}

.category_text h6 {
  color: #606060;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 30px;
}

.category_text li {
  border-bottom: 1px solid #696969;
  font-family: "Garetra Demo";
  font-size: 35px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  max-width: 400px;
  cursor: pointer;
  transition: 0.1s;
  list-style-type: none;
}

.category_text li:hover {
  color: #efa2a2;
}
.trending_box img {
  min-width: 166px;
  height: 185px;
  object-fit: cover;
}

@media (max-width: 767px) {
  .trending_box {
    margin-bottom: 20px;
  }

  .trending_box .trending_text h5 {
    font-size: 19px;
  }
}
.about_main_banner.blogs_page {
  background-image: url("/public/images/ritu-47.jpg");
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: overlay;
  /* height: 89vh; */
}
