.sidebar {
  position: absolute;
  top: 0px;
  height: 100vh;
  width: 250px !important;
  transition: all 0.4s ease;
  background-color: #657f76 !important;
}
.nav_list {
  padding: 0 40px 0px 40px;
  background-color: #657f76;
  overflow-y: scroll;
  overflow-x: hidden;
  text-decoration: none !important;
  list-style: none;
}
.nav_list li {
  margin-bottom: 20px;
}
.nav_list li a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  text-decoration: none;
  font-size: 16px;
  padding: 15px 0 15px 5px;
  transition: all 0.2s ease;
  margin-bottom: 5px;
  font-weight: 400;
}
.collapse_div {
  margin-top: 20px;
}
.nav_list li {
  background-color: #f2f5f7;
  margin-top: 10px !important;
  transition: all 0.3s ease;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.nav_list li:hover {
  cursor: pointer;
  background-color: #f2f5f7;
  color: black !important;
}
.nav_list li a:hover {
  color: black !important;
}
.nav_list li a span {
  opacity: 0;
}
.nav_list li a svg {
  transform: translateX(6px);
  min-width: 20px;
  font-size: 18px;
  color: #495057;
}
.nav_list li:hover svg {
  color: grey !important;
}
.profile {
  color: #f3f3f3;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 36px 0;
  opacity: 0;
  justify-content: center;
}
.profile p {
  padding: 20px;
  border-radius: 0 0 70px 70px;
}
.profile img {
  width: 160px;
  margin-right: 35px;
  object-fit: cover;
}
.sidebar.active .logo_content {
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
}
.logo_content {
  height: 100%;
  max-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo_content .fa-bars {
  color: #0097d9;
  position: absolute;
  left: 50%;
  font-size: 25px;
  text-align: center;
  top: 30px;
  cursor: pointer;
  transform: translateX(-50%);
  transition: all 0.5s ease;
}
.sidebar.active {
  width: 218px;
  box-shadow: -5px 30px 30px 0 rgb(24 91 196 / 5%) !important;
  background-color: #fff;
}

.nav_list li.nav_active {
  background-color: rgb(255, 213, 222);
  /*#e7f2ea*/
}
.sidebar.active .profile {
  opacity: 1;
}
.collapse_div a {
  justify-content: center;
}
.sub_nav a {
  justify-content: center;
}
.sidebar.active .nav_list li a span {
  opacity: 1;
  color: #394767;
  font-weight: 500;
}
.sidebar.active .logo_content .fa-bars {
  left: 90%;
}
.nav_active {
  background-color: #17191f;
}
.nav_active a svg,
.nav_active a span {
  font-weight: 600;
}
.nav_active a img {
  filter: brightness(0) invert(1);
}
.sidebar .nav_list li a img {
  position: relative;
  left: 7px;
}
.sidebar.active .nav_list li a img {
  position: relative;
  left: 0;
}
.nav_list::-webkit-scrollbar {
  width: 5px;
}
.nav_list::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
  outline: 1px solid #fff;
}
.dashboard_nav {
  margin-left: auto;
}
.breadcrumb_main {
  background: #d8dce3;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.breadcrumb_container {
  width: 64%;
  position: relative;
  float: left;
  display: flex;
}
.breadcrumb_container h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.edit_layout_container {
  width: 100%;
  height: 60px;
  background: #dde7f6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #185bc4;
}
.edit_layout_container a {
  text-decoration: none !important;
}
.admin-logo {
  margin: 65px 0 15px 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 90px;
  padding: 10px;
}
